export const locales = {
  Russian: {
    mainLink: 'Главная',
    boards: 'Панели',
    tasks: 'Задачи',
    people: 'Команда',
    profileLink: 'Профиль',
    logInLink: 'Войти',
    signUpLink: 'Зарегестрироваться',
    signOut: 'Выйти',
    createBoard: 'Создать доску',
    goHome: 'Перейти на главную',
  },
  English: {
    mainLink: 'Main',
    boards: 'Boards',
    tasks: 'Tasks',
    people: 'Team',
    profileLink: 'Profile',
    logInLink: 'Log In',
    signUpLink: 'Sign Up',
    signOut: 'Sign Out',
    createBoard: 'Create board',
    goHome: 'Go to home',
  },
};
