export const locales = {
  Russian: {
    creatorTitle: 'Создать задачу',
    noTasksFound: 'Задач не найдено',
    createTask: 'Добавть задачу',
    deleteColumn: 'Удалить колонку',
    updateColumn: 'Обновить',
  },
  English: {
    creatorTitle: 'Create task',
    noTasksFound: 'No tasks found',
    createTask: 'Add task',
    deleteColumn: 'Delete column',
    updateColumn: 'Change',
  },
};
