export const locales = {
  Russian: {
    title: 'Название: ',
    description: 'Описание: ',
    createButton: 'Создать доску',
    titleRequired: 'Укажите название доски',
    descriptionRequired: 'Укажите описание доски',
  },
  English: {
    title: 'Title: ',
    description: 'Description: ',
    createButton: 'Create board',
    titleRequired: 'Write board title',
    descriptionRequired: 'Write board description',
  },
};
