export const locales = {
  Russian: {
    name: 'Имя',
    login: 'Логин',
    password: 'Пароль',
  },
  English: {
    name: 'Name',
    login: 'Login',
    password: 'Password',
  },
};
