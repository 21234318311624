export const locales = {
  Russian: {
    title: 'Подверждение удалиения элемента',
    text: 'Вы действительно хотите удалить?',
    button: 'Удалить',
  },
  English: {
    title: 'Confirm to delete',
    text: 'Are you sure to delete?',
    button: 'Delete',
  },
};
