export const locales = {
  Russian: {
    title: 'Страница авторизации',
    loginRequired: 'Пожалуйста, укажите логин!',
    loginMin: 'Логин должен быть длинее 8 символов!',
    loginPattern: 'Логин должен содержать только цифры и английские буквы!',
    passwordRequired: 'Пожалуйста, укажите пароль!',
    passwordMin: 'Пароль должен быть длинее 8 символов!',
    passwordPattern: 'Пароль должен содержать только цифры и английские буквы!',
  },
  English: {
    title: 'Log in page',
    loginRequired: 'Please input your login!',
    loginMin: 'Login must be longer than 8 characters!',
    loginPattern: 'Login must include only numbers and latin letters!',
    passwordRequired: 'Please input your password!',
    passwordMin: 'Password must be longer than 8 characters!',
    passwordPattern: 'Password must include only numbers and latin letters!',
  },
};
