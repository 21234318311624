export const locales = {
  Russian: {
    titleRequired: 'Введите название',
    descriptionRequired: 'Введите описание',
    title: 'Название',
    description: 'Описание',
    submit: 'Создать задачу',
  },
  English: {
    titleRequired: 'Enter title',
    descriptionRequired: 'Enter description',
    title: 'Title',
    description: 'Description',
    submit: 'Create task',
  },
};
