export const locales = {
  Russian: {
    title: 'Наша команда',
    loading: 'Идёт загрузка',
    deleteButton: 'Удалить пользователя',
  },
  English: {
    title: 'Team members',
    loading: 'Load users',
    deleteButton: 'Delete user',
  },
};
