export const locales = {
  Russian: {
    title: 'Главная',
    boardTitle: 'Название: ',
    boardDescription: 'Описание: ',
    createButton: 'Создать доску',
    deleteButton: 'Удалить доску',
    creatorTitle: 'Форма создания доски',
    showBoard: 'Открыть доску',
    deleteTitle: 'Удалить доску',
    deleteText: 'Вы действительно хотите удалить безвозвратно доску?',
    loading: 'Идёт загрузка ...',
  },
  English: {
    title: 'Main page',
    boardTitle: 'Title: ',
    boardDescription: 'Description: ',
    createButton: 'Create board',
    deleteButton: 'Delete board',
    creatorTitle: 'Board creation board',
    showBoard: 'Open board',
    deleteTitle: 'Delete board',
    deleteText: 'Are you sure to delete board?',
    loading: 'Loading data ...',
  },
};
