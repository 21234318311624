export const locales = {
  Russian: {
    title: 'Название: ',
    description: 'Описание: ',
    done: 'Выполнен',
    undone: 'Не выполнен',
    delete: 'Удалить задачу',
    changeData: 'Изменить содержимое задачи',
    modalTitle: 'Текущая задача',
    update: 'Обновить',
    showButton: 'Показать задачу',
    updateUser: 'Последние изменения сделаны:',
    deletedUser: 'Удаленным пользователем',
  },
  English: {
    title: 'Title: ',
    description: 'Description: ',
    done: 'Complited',
    undone: 'Not complited',
    delete: 'Delete Task',
    changeData: 'Change task data',
    modalTitle: 'Current task',
    update: 'Update',
    showButton: 'Show task',
    updateUser: 'Latest changes by:',
    deletedUser: 'Deleted user',
  },
};
