export const locales = {
  Russian: {
    title: 'Страница профиля',
    login: 'Логин',
    name: 'Имя',
    password: 'Пароль',
    button: 'Сохранить',

    deleteButton: 'Удалить',
    resetButton: 'Сбросить',
    loading: 'Идёт загрузка данных профиля',
    deleteTitle: 'Удалить пользователя',
    updateProfile: 'Данные пользователя обновляются ...',
    successUpdate: 'Профиль успешно обновлён',
  },
  English: {
    title: 'Profile page',
    login: 'Login',
    name: 'Name',
    password: 'Password',
    button: 'Update',
    deleteButton: 'Delete',
    resetButton: 'Reset',
    loading: 'Profile data load ...',
    deleteTitle: 'Delete user',
    updateProfile: 'Profile data update ...',
    successUpdate: 'Profile updated successful',
  },
};
