export const locales = {
  Russian: {
    title: 'Планируй и контролируй свой проект с нами',
    profile: 'Твой профиль',
    joinUs: 'Присоединиться',
    text: 'Проект: Система управления проектами – приложение помогающее достичь поставленные задачи отдельному человеку в команде или группе разработчиков.',
    decription: 'Проект реализован в рамках обучения в Rolling-Scopes-School с 3 по 31 мая 2022г',
  },
  English: {
    title: 'Plan and control your project with us',
    profile: 'Your profile',
    joinUs: 'Join to us',
    text: 'Project: Project management system is an application that helps an individual in a team or group of developers to achieve their tasks.',
    decription:
      'Project implemented as part of the Rolling-Scopes-School task from 3 to 31 May 2022',
  },
};
